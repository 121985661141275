import React, { useEffect, useState, useRef, useMemo } from 'react';
import Sidebar from '../../layout/sidebar/Sidebar';
import Axios from '../../../services/AxiosInstance';
import JoditEditor from 'jodit-react';

const CreateQuestion = () => {
    const [questionTypeList, setQuestionTypeList] = useState([]);
    const [questionType, setQuestionType] = useState([]);
    const [question, setQuestion] = useState('');
    const [option1, setOption1] = useState('');
    const [option2, setOption2] = useState('');
    const [option3, setOption3] = useState('');
    const [option4, setOption4] = useState('');
    const [answer, setAnswer] = useState('');

    const [placeholder, setPlaceholder] = useState('');

    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const getQuestionTypes = () => {
        Axios.get(`api/admin/get-question-type`)
            .then((res) => {
                console.log("Hello", res);
                setQuestionTypeList(res.data.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }
    useEffect(() => {
        getQuestionTypes();
    }, [])

    const showQuestionType = questionTypeList.map((type, index) => {
        return (
            <option key={index} value={type.question_type_id}>{type.question_type}</option>
        )
    })
    // console.log("bhar", option1);

    const handleQuestionsApi = () => {
        let question_type_name = '';
        for (let i = 0; i < questionTypeList.length; i++) {
            if (questionType == questionTypeList[i].question_type_id) {
                question_type_name = questionTypeList[i].question_type;
            }
        }

        if (!question_type_name) {
            setErrorMessage("Please Select Question Type!");
            setIsError(true);
            return;
        }


        if (!question || question === '<p><br></p>') {
            setErrorMessage("Please Enter Question!");
            setIsError(true);
            return;
        }
        if (!option1 || option1 === '<p><br></p>') {
            setErrorMessage("Please Enter Option 1!");
            setIsError(true);
            return;
        } if (!option2 || option2 === '<p><br></p>') {
            setErrorMessage("Please Enter Option 2!");
            setIsError(true);
            return;
        } if (!answer || answer === '<p><br></p>') {
            setErrorMessage("Please Enter Answer!");
            setIsError(true);
            return;
        }

        const dynamicVariable = process.env.REACT_APP_TEST_IMAGE_BASE_URL;
        const pattern = new RegExp(dynamicVariable, "g");
        let str1 = question.replace(pattern, '').replace(/"/g, "\\'");
        let str2 = option1.replace(pattern, '').replace(/"/g, "\\'");
        let str3 = option2.replace(pattern, '').replace(/"/g, "\\'");
        let str4 = option3.replace(pattern, '').replace(/"/g, "\\'");
        let str5 = option4.replace(pattern, '').replace(/"/g, "\\'");
        let str6 = answer.replace(pattern, '').replace(/"/g, "\\'");
        // console.log(str2);
        // return;
        Axios.post(`api/admin/add-new-question`, {
            question: str1,
            question_type_id: questionType,
            question_type: question_type_name,
            option1: str2,
            option2: str3,
            option3: str4,
            option4: str5,
            answer: str6,
        })
            .then((res) => {
                console.log(res);
                setErrorMessage('');
                setIsError(false);
            })
            .catch((error) => {
                console.log(error);
            })
    }


    const editor = useRef(null);

    let first = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/,
        placeholder: placeholder || 'Start typings...',
        height: '200px',
        width: '100%',
        enableDragAndDropFileToEditor: true,
        buttons: ['bold', 'italic', 'underline', '|', 'ul', 'ol', '|', 'font', 'fontsize', 'brush', 'paragraph', '|', 'table', '|', 'left', 'center', 'right', 'justify', '|', 'image'],
        uploader: {
            url: `${process.env.REACT_APP_BASE_URL}api/admin/upload-test-image`,
            format: "json",
            headers: {
                Authorization: `${localStorage.getItem('token')}`
            },
            prepareData: (formData) => {
                formData.append('isQuestion', "Yes");

                // console.log(formData);
                // You can prepare data for uploading here
                return formData;
            },
            isSuccess: (response) => {
                // Check if the upload was successful
                // console.log(response.data);
                return response.code === "success" && response.data.imageUrl;
            },
            process: function (resp) {
                return {
                    files: resp.files || [],
                    path: resp.data.imageUrl,
                    baseurl: process.env.REACT_APP_TEST_IMAGE_BASE_URL,
                    // error: resp.error,
                    // msg: resp.data.imageUrl
                };
            },

            defaultHandlerSuccess: function (data, resp) {
                // var i, field = 'files';
                console.log(this)
                this.s.insertImage(data.baseurl + data.path);
                // if (data[field] && data[field].length) {
                //     for (i = 0; i < data[field].length; i += 1) {
                //         console.log(data.baseurl + data[field][i]);
                //         this.s.insertImage(data.baseurl + data[field][i]);
                //     }
                // }
            },
            error: function (e) {
                this.e.fire('errorMessage', [e.getMessage(), 'error', 4000]);
            }
            // insertImageAsBase64URI: false
        },
        removeButtons: ['brush', 'file'],
        showXPathInStatusbar: false,
        showCharsCounter: false,
        showWordsCounter: false,
        toolbarAdaptive: true,
        toolbarSticky: true,
        style: {
            background: '#27272E',
            color: 'rgba(255,255,255,0.5)',
        },
    }



    const config = useMemo(() =>
        first,
        [placeholder]
    );


    const editor1 = useRef(null);

    let first1 = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/,
        placeholder: placeholder || 'Start typings...',
        height: '200px',
        width: '100%',
        enableDragAndDropFileToEditor: true,
        buttons: ['bold', 'italic', 'underline', '|', 'ul', 'ol', '|', 'font', 'fontsize', 'brush', 'paragraph', '|', 'table', '|', 'left', 'center', 'right', 'justify', '|', 'image'],
        uploader: {
            url: `${process.env.REACT_APP_BASE_URL}api/admin/upload-test-image`,
            format: "json",
            headers: {
                Authorization: `${localStorage.getItem('token')}`
            },
            prepareData: (formData) => {
                formData.append('isQuestion', "No");

                // console.log(formData);
                // You can prepare data for uploading here
                return formData;
            },
            isSuccess: (response) => {
                // Check if the upload was successful
                // console.log(response.data);
                return response.code === "success" && response.data.imageUrl;
            },
            process: function (resp) {
                return {
                    files: resp.files || [],
                    path: resp.data.imageUrl,
                    baseurl: process.env.REACT_APP_TEST_IMAGE_BASE_URL,
                    // error: resp.error,
                    // msg: resp.data.imageUrl
                };
            },

            defaultHandlerSuccess: function (data, resp) {
                // var i, field = 'files';
                console.log(this)
                this.s.insertImage(data.baseurl + data.path);
                // if (data[field] && data[field].length) {
                //     for (i = 0; i < data[field].length; i += 1) {
                //         console.log(data.baseurl + data[field][i]);
                //         this.s.insertImage(data.baseurl + data[field][i]);
                //     }
                // }
            },
            error: function (e) {
                this.e.fire('errorMessage', [e.getMessage(), 'error', 4000]);
            }
            // insertImageAsBase64URI: false
        },
        removeButtons: ['brush', 'file'],
        showXPathInStatusbar: false,
        showCharsCounter: false,
        showWordsCounter: false,
        toolbarAdaptive: true,
        toolbarSticky: true,
        style: {
            background: '#27272E',
            color: 'rgba(255,255,255,0.5)',
        },
    }



    const config1 = useMemo(() =>
        first1,
        [placeholder]
    );



    return (
        <>
            <Sidebar />
            <div className='abt-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='abt-row'>
                                <h2>Create Question</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='slect-option-section'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='slect-form'>
                                    <div className='slect-options'>
                                        <label>Select Question Type</label>
                                        <select type="text" onChange={(e) => setQuestionType(e.target.value)}>
                                            <option>select question type</option>
                                            {showQuestionType}
                                        </select>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='slect-form'>
                                    <div className='slect-options'>
                                        <label>Enter Question</label>
                                        {/* <input type="text" value={question} onChange={(e) => setQuestion(e.target.value)} /> */}
                                        <div className='jodi-editor'>
                                            <JoditEditor
                                                ref={editor}
                                                value={question}
                                                config={config}
                                                tabIndex={1} // tabIndex of textarea
                                                onBlur={newContent => setQuestion(newContent)} // preferred to use only this option to update the content for performance reasons
                                                onChange={newContent => setQuestion(newContent)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='slect-form'>
                                    <div className='slect-options'>
                                        <label>Enter Option 1</label>
                                        {/* <input type="text" value={option1} onChange={(e) => setOption1(e.target.value)} /> */}
                                        <div className='jodi-editor'>
                                            <JoditEditor
                                                ref={editor1}
                                                value={option1}
                                                config={config1}
                                                tabIndex={1} // tabIndex of textarea
                                                onBlur={newContent => setOption1(newContent)} // preferred to use only this option to update the content for performance reasons
                                                onChange={newContent => setOption1(newContent)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='slect-form'>
                                    <div className='slect-options'>
                                        <label>Enter Option 2</label>
                                        {/* <input type="text" value={option2} onChange={(e) => setOption2(e.target.value)} /> */}
                                        <div className='jodi-editor'>
                                            <JoditEditor
                                                ref={editor1}
                                                value={option2}
                                                config={config1}
                                                tabIndex={1} // tabIndex of textarea
                                                onBlur={newContent => setOption2(newContent)} // preferred to use only this option to update the content for performance reasons
                                                onChange={newContent => setOption2(newContent)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            questionType != 1 ?
                                <>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='slect-form'>
                                                <div className='slect-options'>
                                                    <label>Enter Option 3</label>
                                                    {/* <input type="text" value={option3} onChange={(e) => setOption3(e.target.value)} /> */}
                                                    <div className='jodi-editor'>
                                                        <JoditEditor
                                                            ref={editor1}
                                                            value={option3}
                                                            config={config1}
                                                            tabIndex={1} // tabIndex of textarea
                                                            onBlur={newContent => setOption3(newContent)} // preferred to use only this option to update the content for performance reasons
                                                            onChange={newContent => setOption3(newContent)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='slect-form'>
                                                <div className='slect-options'>
                                                    <label>Enter Option 4</label>
                                                    {/* <input type="text" value={option4} onChange={(e) => setOption4(e.target.value)} /> */}
                                                    <div className='jodi-editor'>
                                                        <JoditEditor
                                                            ref={editor1}
                                                            value={option4}
                                                            config={config1}
                                                            tabIndex={1} // tabIndex of textarea
                                                            onBlur={newContent => setOption4(newContent)} // preferred to use only this option to update the content for performance reasons
                                                            onChange={newContent => setOption4(newContent)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : ""
                        }


                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='slect-form'>
                                    <div className='slect-options'>
                                        <label>Enter Answer</label>
                                        {/* <input type="text" value={answer} onChange={(e) => setAnswer(e.target.value)} /> */}
                                        <div className='jodi-editor'>
                                            <JoditEditor
                                                ref={editor1}
                                                value={answer}
                                                config={config1}
                                                tabIndex={1} // tabIndex of textarea
                                                onBlur={newContent => setAnswer(newContent)} // preferred to use only this option to update the content for performance reasons
                                                onChange={newContent => setAnswer(newContent)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isError ? <span>{errorMessage}</span> : ""}
                        <button onClick={handleQuestionsApi} className="add-question">Add Question</button>

                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateQuestion;