import React from 'react';
import { useState } from 'react';
import { AiFillDashboard } from "react-icons/ai";
import { AiOutlineMenu } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";
import { FaUserPlus } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import logoimg from '../../../assets/images/logo-img1.png';
import logoimg1 from '../../../assets/images/logo-img2.png';
import { Link } from 'react-router-dom';

const Sidebar = () => {
    const [isWhatsAppOpen, setIsWhatsAppOpen] = useState(false);
    const [isManageMenu, setIsManageMenu] = useState(false);
    const [isManageContentMenu, setIsManageContentMenu] = useState(false);
    const [isManageExamPaper, setIsManageExamPaper] = useState(false);
    const [isGemOpen, setIsGemOpen] = useState(false);
    const [issustinabiltyOpen, setIssustinabiltyOpen] = useState(false);
    const [isNavOpen, setIsNavOpen] = useState(true);
    const [IsMagestateOpen, setIsMagestateOpen] = useState(false)
    const [IsManageGemChapterOpen, setIsManageGemChapterOpen] = useState(false)

    const handleSideNav = () => {
        console.log(isNavOpen);
        if (isNavOpen) {
            setIsNavOpen(false);
        } else {
            setIsNavOpen(true);
        }
    }

    const handleIsOpenWhatsApp = () => {
        if (isWhatsAppOpen) {
            setIsWhatsAppOpen(false);
        } else {
            setIsWhatsAppOpen(true);

        }

    }
    const handleIsManageMenu = () => {
        if (isManageMenu) {
            setIsManageMenu(false);
        } else {
            setIsManageMenu(true);

        }

    }

    const handleIsManageContentMenu = () => {
        if (isManageContentMenu) {
            setIsManageContentMenu(false);
        } else {
            setIsManageContentMenu(true);

        }

    }

    const handleIsManageExamPaper = () => {
        if (isManageExamPaper) {
            setIsManageExamPaper(false);
        } else {
            setIsManageExamPaper(true);

        }

    }

    const handleIsOpensustinabilty = () => {
        if (issustinabiltyOpen) {
            setIssustinabiltyOpen(false);
        } else {
            setIssustinabiltyOpen(true);

        }

    }
    const handleIsMagestate = () => {
        if (IsMagestateOpen) {
            setIsMagestateOpen(false);
        } else {
            setIsMagestateOpen(true);

        }

    }
    const handleIsOpenGem = () => {
        if (isGemOpen) {
            setIsGemOpen(false);
        } else {
            setIsGemOpen(true);

        }

    }

    const handleIsManageGemChapterOpen = () => {
        if (isGemOpen) {
            setIsManageGemChapterOpen(false);
        } else {
            setIsManageGemChapterOpen(true);

        }
    }


    return (
        <div className='sider-menu'>

            <div className="slider-bar-logo">
                <ul>
                    <li onClick={handleSideNav}><AiOutlineMenu /></li>
                    <li><img src={logoimg1} alt="" /></li>
                    <li><img src={logoimg} alt="" /></li>
                </ul>

            </div>
            <div className={isNavOpen ? 'side-menu-list open  slim-scroll' : 'side-menu-list  slim-scroll'} >
                <ul>
                    <li>
                        <Link><AiFillDashboard />Dashboard</Link>
                        <FaAngleDown />
                    </li>

                    <li className={isManageMenu ? "mm-active" : ""} >

                        <Link aria-expanded={isManageMenu ? "true" : "false"} onClick={handleIsManageMenu}><FaUserAlt />Manage Menu<span><FaAngleDown /></span></Link>

                        <ul className={isManageMenu ? "nav-second-level mm-collapse mm-show" : "nav-second-level mm-collapse"} aria-expanded="false" >
                            <li>
                                <Link to="/manage-menu">
                                    <span>Menu</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/manage-sub-menu">
                                    <span>sub Menu</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/manage-content-menu">
                                    <span>Content Menu</span>
                                </Link>
                            </li>

                        </ul>
                    </li>


                    <li className={isManageContentMenu ? "mm-active" : ""} >

                        <Link aria-expanded={isManageContentMenu ? "true" : "false"} onClick={handleIsManageContentMenu}><FaUserAlt />Manage CMS Content<span><FaAngleDown /></span></Link>

                        <ul className={isManageContentMenu ? "nav-second-level mm-collapse mm-show" : "nav-second-level mm-collapse"} aria-expanded="false" >
                            <li>
                                <Link to="/add-content">
                                    <span>Add CMS Content</span>
                                </Link>
                            </li>


                            <li>
                                <Link to="/static-Content-list">
                                    <span>CMS Content List</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/manage-event">
                                    <span>Manage Event</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/event-list">
                                    <span>Event List</span>
                                </Link>
                            </li>


                        </ul>
                    </li>


                    <li className={isManageExamPaper ? "mm-active" : ""} >

                        <Link aria-expanded={isManageExamPaper ? "true" : "false"} onClick={handleIsManageExamPaper}><FaUserAlt />Manage Exam<span><FaAngleDown /></span></Link>

                        <ul className={isManageExamPaper ? "nav-second-level mm-collapse mm-show" : "nav-second-level mm-collapse"} aria-expanded="false" >
                            <li>
                                <Link to="/create-question">
                                    <span>Add Question</span>
                                </Link>
                            </li>


                            <li>
                                <Link to="/assigned-paper">
                                    <span>Create Exam Paper</span>
                                </Link>
                            </li>

                        </ul>
                    </li>


                    <li>
                        <Link onClick={handleIsMagestate}><FaUserAlt />Manage state<span><FaAngleDown /></span></Link>

                        <ul className={IsMagestateOpen ? "nav-second-level mm-collapse mm-show" : "nav-second-level mm-collapse"} aria-expanded="false" >
                            <li>
                                <Link to="/States">
                                    <span>States</span>
                                </Link>
                            </li>
                        </ul>
                    </li>


                    <li>
                        <Link onClick={handleIsManageGemChapterOpen}><FaUserAlt />Manage State Chapters<span><FaAngleDown /></span></Link>
                        <ul className={IsManageGemChapterOpen ? "nav-second-level mm-collapse mm-show" : "nav-second-level mm-collapse"} aria-expanded="false" >
                            <li>
                                <Link to="/gem-state-chapters-manager">
                                    <span>Add State Chapter</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/gem-state-chapters-list">
                                    <span>List</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li><Link to={'/gem-registration-list'}><FaUserPlus />Register For GEM</Link><FaAngleDown /></li>

                </ul>

            </div>
        </div>
    )
}

export default Sidebar