import React from 'react';
import { useState } from 'react';
import logoimg from '../../../assets/images/logo-img1.png';
import logoimg1 from '../../../assets/images/logo-img2.png';
import adminimg from '../../../assets/images/admin-img.png'
import { FaBell } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";

const Header = (props) => {
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const Navigate = useNavigate();

    const handleIsOpenProfile = () => {
        if (isProfileOpen) {
            setIsProfileOpen(false);
        } else {
            setIsProfileOpen(true);

        }
    }

    const handleLogout = () => {
        localStorage.clear();
        props.setIsheader(false);
        Navigate('/login')
    }
    return (
        <div className='top-header'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3'>

                    </div>
                    <div className='col-md-3'>

                    </div>
                    <div className='col-md-4'>
                        <div className='slider-icon'>
                            <ul>
                                <li><Link><FaBell /></Link></li>
                                <li><Link><FaQuestionCircle /></Link></li>
                                <li><Link><FaEnvelope /></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <div className='right-profile'>
                            <div className='profile-cont'>
                                <ul>
                                    <li className={isProfileOpen ? "mm-active" : ""} >

                                        <Link aria-expanded={isProfileOpen ? "true" : "false"} onClick={handleIsOpenProfile}><img src={adminimg} alt="" />Admin<span><FaAngleDown /></span></Link>

                                        <ul className={isProfileOpen ? "nav-second-level profile-list mm-collapse mm-show" : "nav-second-level mm-collapse"} aria-expanded="false" >



                                            <li><Link>My Profile</Link></li>
                                            <li><Link>Settings</Link></li>
                                            <li><button onClick={handleLogout}>Log Out</button></li>

                                        </ul>
                                    </li>
                                </ul>


                            </div>
                            {/* <div className='profile-list'>

</div> */}
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Header;

// https://themewagon.github.io/pluto/dashboard.html