import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Axios from '../../../services/AxiosInstance';
import Sidebar from '../../layout/sidebar/Sidebar';
import RenderHtml from './htmlRender';
import CustomPagination from '../../common/Pagination';
import Loader from '../../common/Loader';


const StaticContentList = () => {
    const Navigate = useNavigate();
    const [staticContentList, setStaticContentList] = useState([]);

    const [menuList, setMenuList] = useState([]);
    const [subMenuList, setSubMenuList] = useState([]);
    const [contentList, setContentList] = useState([]);

    const [subMenuID, setSubMenuID] = useState(0);
    const [contentMenuId, setContentMenuId] = useState(null);
    const [menuId, setMenuId] = useState('');

    const [totalPage, setTotalPage] = useState(0);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(2);

    const [sortConfig, setSortConfig] = useState({ key: null, direction: "ASC", });

    const [isLoading, setIsLoading] = useState({ contentDelete: false });

    const [isContentIdLoading, setIsContentIdLoading] = useState('');



    const staticList = () => {
        Axios.get(`api/admin/static-list?content_menu_id=${contentMenuId}&page_num=${pageNum}&per_page_records=${pageSize}&sort_key=${sortConfig.key}&sort_direction=${sortConfig.direction}`)
            .then((res) => {
                console.log(res.data.data.total_pages);
                setStaticContentList(res.data.data.data);
                setTotalPage(res.data.data.total_pages);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const showStaticContentList = staticContentList.map((menu, index) => {
        return (
            <tr key={index}>
                <td>{menu.content_id}</td>
                <td>{menu.menu_id}</td>
                <td>{menu.sub_menu_id}</td>
                <td>{menu.content_menu_id}</td>
                <td><RenderHtml htmlString={menu.content} /></td>
                <td>{menu.status}</td>
                <td>{new Date(menu.added_date).toDateString()}</td>
                <td>{menu.added_by}</td>
                <td>{new Date(menu.modified_date).toDateString()}</td>
                <td>{menu.modified_by}</td>
                <td>
                    <button onClick={() => handleUpdateContent(menu.content_id)}>Update Content</button>
                    {isLoading.contentDelete && isContentIdLoading === menu.content_id ? <Loader /> : <button onClick={() => handleDeleteMenuName(menu.content_id)}>Delete</button>}
                </td>
            </tr>
        )
    })

    const handleUpdateContent = (content_id) => {
        Navigate(`/static-Content-update?content_id=${content_id}`);
    }


    const handleDeleteMenuName = (content_id) => {
        setIsContentIdLoading(content_id);
        Axios.post('api/admin/delete-static-content', {
            content_id: content_id,
        }, setIsLoading({ ...isLoading, contentDelete: true }))
            .then((res) => {
                setTimeout(() => {
                    setIsLoading({ ...isLoading, contentDelete: false })
                    if (res?.status === 200) {
                        staticList();
                    }
                }, 2000)

            })
            .catch((error) => {
                console.log(error);
                setTimeout(() => {
                    setIsLoading({ ...isLoading, contentDelete: false })
                }, 2000)
            })
    }


    useEffect(() => {
        staticList();
    }, [pageNum, pageSize, sortConfig, contentMenuId])

    useEffect(() => {
        getMenuList();
    }, [])

    const getContentList = (menuId, subMenuID) => {
        Axios.get(`api/admin/content-list?menu_id=${menuId}&sub_menu_id=${subMenuID}`)
            .then((res) => {
                console.log(res);
                setContentList(res.data.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const getSubMenuList = (parent_id) => {
        Axios.get(`api/admin/get-sub-menu-list?parent_id=${parent_id}`)
            .then((res) => {
                console.log("Sub Menu Id", res);
                setSubMenuList(res.data.data);
                if (res?.data?.data.length <= 0) {
                    getContentList(parent_id, 0);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getMenuList = () => {
        Axios.get(`api/admin/get-menu-list`)
            .then((res) => {
                console.log(res);
                setMenuList(res.data.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const showMenuOption = menuList.map((menu, index) => {
        return (
            <option key={index} value={menu.menu_id}>{menu.menu_name}</option>

        )
    })

    const showSubMenuOption = subMenuList.map((menu, index) => {
        return (
            <option key={index} value={menu.menu_id}>{menu.menu_name}</option>

        )
    })

    const showContentOption = contentList.map((menu, index) => {
        return (
            <option key={index} value={menu.content_menu_id}>{menu.content_menu_name}</option>
        )
    })


    const handleSelectMenuId = (menu_id) => {
        setMenuId(menu_id);
        setPageNum(1);
        getSubMenuList(menu_id);
    }
    const handleSubMenuSelect = (sub_menu_id) => {
        setSubMenuID(sub_menu_id);
        getContentList(menuId, sub_menu_id);
    }

    const handleContentMenuSelect = (content_menu_id) => {
        setContentMenuId(content_menu_id);
        staticList(content_menu_id);
    }


    const handleSort = (key) => {
        let direction = "ASC";
        if (
            sortConfig.key === key &&
            sortConfig.direction === "ASC"
        ) {
            direction = "DESC";
        }
        setSortConfig({ key, direction });
    };

    const sortedData = React.useMemo(() => {
        let sorted = [...staticContentList];
        if (sortConfig.key !== null) {
            sorted.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === "ASC" ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === "ASC" ? 1 : -1;
                }
                return 0;
            });
        }
        return sorted;
    }, [staticContentList, sortConfig]);

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setPageNum(1);
    }

    return (
        <>
            <Sidebar />
            <div className='abt-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='abt-row'>
                                <h2>Update Static Content</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='slect-option-section'>
                    <div className='container'>
                        <div className='row'>
                            <div className='slect-form'>
                                <div className='slect-options'>
                                    <label>Select Menu</label>
                                    <select
                                        name=""
                                        id=""
                                        onChange={(e) => handleSelectMenuId(e.target.value)}
                                    >
                                        <option>Select Menu</option>
                                        {showMenuOption}
                                    </select>
                                </div>
                            </div>
                            {
                                subMenuList.length ?
                                    <div className='slect-form'>
                                        <div className='slect-options'>
                                            <label>Select Sub Menu</label>
                                            <select
                                                name=""
                                                id=""
                                                onChange={(e) => handleSubMenuSelect(e.target.value)}
                                            >
                                                <option>Select Sub Menu</option>
                                                {showSubMenuOption}
                                            </select>
                                        </div>

                                    </div> : ""
                            }
                            {
                                contentList.length ?
                                    <div className='slect-form'>
                                        <div className='slect-options'>
                                            <label>Select Centent Menu</label>
                                            <select
                                                name=""
                                                id=""
                                                onChange={(e) => handleContentMenuSelect(e.target.value)}
                                            >
                                                <option>Select Sub Menu</option>
                                                {showContentOption}
                                            </select>
                                        </div>

                                    </div> : ""
                            }
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <CustomPagination pageNum={pageNum} setPageNum={setPageNum} totalPage={totalPage} />
                                <div className='pg-slct-list'>
                                    <label>Page Size</label>
                                    <select onChange={(e) => handlePageSizeChange(e.target.value)}>
                                        <option >Select</option>
                                        <option value="1" >1</option>
                                        <option value="2" >2</option>
                                        <option value="5" >5</option>
                                        <option value="10" >10</option>
                                        <option value="20" >20</option>
                                    </select>
                                </div>
                                <div className='menu-view-cont table-responsive'>
                                    <table>
                                        <tr>
                                            <th>
                                                Content Id
                                                <span className={`sorting-logo ${sortConfig.key === "content_id" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("content_id")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "content_id" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("content_id")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>
                                                Menu Id
                                                <span className={`sorting-logo ${sortConfig.key === "menu_id" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("menu_id")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "menu_id" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("menu_id")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>
                                                Sub Menu Id
                                                <span className={`sorting-logo ${sortConfig.key === "sub_menu_id" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("sub_menu_id")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "sub_menu_id" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("sub_menu_id")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>
                                                Content Menu Id
                                                <span className={`sorting-logo ${sortConfig.key === "content_menu_id" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("content_menu_id")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "content_menu_id" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("content_menu_id")}>
                                                    ▼
                                                </span>
                                            </th>

                                            <th>Content</th>
                                            <th>Status</th>

                                            <th>
                                                Added Date
                                                <span className={`sorting-logo ${sortConfig.key === "added_date" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("added_date")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "added_date" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("added_date")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>Added By</th>
                                            <th>
                                                Modified Date
                                                <span className={`sorting-logo ${sortConfig.key === "modified_date" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("modified_date")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "modified_date" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("modified_date")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>Modified By</th>
                                            <th>Action</th>
                                        </tr>
                                        {showStaticContentList}
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StaticContentList;