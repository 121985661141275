import React from 'react'
import Pagination from 'react-bootstrap/Pagination';

const CustomPagination = (props) => {
    const item = [];

    // Add "Ellipsis" item to indicate previous pages
    if (props.pageNum > 2) {
        item.push(<Pagination.Ellipsis key="ellipsis-prev" />);
    }

    for (let i = Math.max(1, props.pageNum - 2); i <= Math.min(props.pageNum + 2, props.totalPage); i++) {
        item.push(
            <Pagination.Item
                key={i}
                active={i === props.pageNum}
                onClick={() => props.setPageNum(i)}
            >
                {i}
            </Pagination.Item>
        );
    }

    // Add "Ellipsis" item to indicate next pages
    if (props.pageNum < props.totalPage - 1) {
        item.push(<Pagination.Ellipsis key="ellipsis-next" />);
    }


    const handlePrevButton = () => {
        if (props.pageNum <= 1) {
            props.setPageNum(1);
        }
        else {
            props.setPageNum(props.pageNum - 1);
        }
    }
    const handleNextButton = () => {
        if (props.pageNum >= props.totalPage)
            props.setPageNum(props.totalPage);
        else {
            props.setPageNum(props.pageNum + 1);
        }
    }
    return (
        <div className='pagintion-list'>
            <Pagination>
                <Pagination.Prev onClick={() => handlePrevButton()} />
                {item}
                <Pagination.Next onClick={() => handleNextButton()} />
            </Pagination>
        </div>
    )
}

export default CustomPagination;