import React, { useEffect, useState } from 'react'
import Sidebar from '../../layout/sidebar/Sidebar';
import Axios from '../../../services/AxiosInstance';
import Loader from '../../common/Loader';

const ManageMenu = () => {
    const [menuList, setMenuList] = useState([]);
    const [menuName, setMenuName] = useState('');
    const [errorMessage, setErrorMessage] = useState();
    const [isError, setIsError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [isSuccess, setIsSuccess] = useState('');

    const [updateMenu, setUpdateMenu] = useState({ oldvalue: '', value: '', menu_id: '', isUpdate: false });

    const getMenuList = () => {
        Axios.get(`api/admin/get-menu-list`)
            .then((res) => {
                console.log(res);
                setMenuList(res.data.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const showMenuList = menuList.map((menu, index) => {
        return (
            <tr key={index}>
                <td>{menu.menu_id}</td>
                <td>
                    {
                        updateMenu.menu_id == menu.menu_id && updateMenu.isUpdate ?
                            <>
                                <input value={updateMenu.value} onChange={(e) => setUpdateMenu({ ...updateMenu, value: e.target.value, isUpdate: true })} />
                                {isLoading ? <Loader /> : ""}
                            </>
                            :
                            menu.menu_name
                    }
                </td>
                <td>{menu.added_by}</td>
                <td>{new Date(menu.added_date).toDateString()}</td>
                <td>{menu.modified_by}</td>
                <td>{new Date(menu.modified_date).toDateString()}</td>
                {/* <td>{menu.parent_id}</td> */}
                <td>{menu.status}</td>
                <td>
                    {!updateMenu.isUpdate ?
                        < button onClick={() => setUpdateMenu({ oldvalue: menu.menu_name, value: menu.menu_name, menu_id: menu.menu_id, isUpdate: true })}>update</button>
                        :
                        < button onClick={() => handleUpdateMenuName()}>Update</button>
                    }
                    <button onClick={() => handleDeleteMenuName(menu.menu_id)}>Delete</button>
                </td>
            </tr >
        )
    })

    const handleUpdateMenuName = () => {
        if (updateMenu.oldvalue !== updateMenu.value && updateMenu.isUpdate && updateMenu.value) {
            Axios.post('api/admin/update-menu-name', {
                menu_name: updateMenu.value,
                menu_id: updateMenu.menu_id
            }, setIsLoading(true))
                .then((res) => {
                    console.log(res.status);
                    if (res?.status === 200) {
                        setUpdateMenu({ oldvalue: '', value: '', menu_id: '', isUpdate: false });
                        getMenuList();
                    }
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 3000)
                })
                .catch((error) => {
                    console.log(error)
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 3000)
                })
        }
    }

    const handleDeleteMenuName = (menu_id) => {
        Axios.post('api/admin/delete-menu-name', {
            menu_id: menu_id,
        })
            .then((res) => {
                if (res?.status === 200) {
                    getMenuList();
                }
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000)

            })
            .catch((error) => {
                console.log(error);
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000)
            })
    }

    useEffect(() => {
        getMenuList();
    }, [])


    const handleAddMenu = () => {
        setIsSuccess('');
        if (!menuName) {
            setErrorMessage("Please Enter Something!");
            setIsError(true);
            return;
        }

        Axios.post(`api/admin/add-menu`, {
            menu_name: menuName
        }, setIsLoading(true))
            .then((res) => {
                console.log(res);
                setIsError(false);
                setErrorMessage('');
                getMenuList();
                if (res?.data?.code == "success") {
                    setIsSuccess("Menu Successfully Created");
                }
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000)
            })
            .catch((error) => {
                console.log(error);
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000)
                if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                    setIsError(true);
                }
            })
    }


    const handleMenuNameChange = (menu_name) => {
        setMenuName(menu_name);
        setErrorMessage('');
        setIsError(false);
        setIsSuccess('');
    }
    return (
        <>
            <Sidebar />
            <div className='abt-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='abt-row'>
                                <h2>Menu Manager</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='slect-option-section'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='slect-form'>
                                    <div className='slect-sub-option'>
                                        <label htmlFor='add-menu'>Add Menu</label>
                                        <input
                                            name="add-menu"
                                            id="add-menu"
                                            value={menuName}
                                            onChange={(e) => handleMenuNameChange(e.target.value)}
                                        />

                                        <button onClick={handleAddMenu}>Add</button>
                                        {isError ? <span>{errorMessage}</span> : ""}
                                        {isLoading ? <Loader /> : ""}
                                        {isSuccess ? <span style={{ color: "green" }}>{isSuccess}</span> : ""}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>

                            <div className='col-md-12'>
                                <div className='menu-view-cont'>
                                    <table>
                                        <tr>
                                            <th>Menu Id</th>
                                            <th>Menu Name</th>
                                            <th>Added By</th>
                                            <th>Added Date</th>
                                            <th>Modified By</th>
                                            <th>Modified Date</th>
                                            {/* <th>Parent Id</th> */}
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                        {showMenuList}
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageMenu;