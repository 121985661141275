import React, { useEffect, useState } from 'react';
import Sidebar from '../../layout/sidebar/Sidebar';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Axios from '../../../services/AxiosInstance';
import Loader from '../../common/Loader';
const GemChapterUpdate = () => {
    const [searchParams] = useSearchParams();
    const Navigate = useNavigate();

    const [chairmanName, setChairmanName] = useState('');
    const [chairmanDesignation, setChairmanDesignation] = useState('');
    const [chairmanImage, setChairmanImage] = useState('');
    const [chairmanImageOld, setChairmanImageOld] = useState('');
    const [coChairmanName, setCoChairmanName] = useState('');
    const [coChairmanDesignation, setCoChairmanDesignation] = useState('');
    const [coChairmanImage, setCoChairmanImage] = useState('');
    const [coChairmanImageOld, setCoChairmanImageOld] = useState('');


    const [errorMessage, setErrorMessage] = useState();
    const [isError, setIsError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState('');


    const getGemStateChapterData = () => {
        Axios.get(`api/admin/get-gem-state-chapter-with-id?id=${searchParams.get('id')}`)
            .then((res) => {
                console.log("asdas", res);
                setChairmanName(res.data.data[0].chairman_name);
                setChairmanDesignation(res.data.data[0].chairman_designation);
                setChairmanImageOld(res.data.data[0].chairman_image);
                setCoChairmanName(res.data.data[0].co_chairman_name);
                setCoChairmanDesignation(res.data.data[0].chairman_designation);
                setCoChairmanImageOld(res.data.data[0].co_chairman_image);
            })
            .catch((error) => {
                console.log(error);
            })
    }
    useEffect(() => {
        if (!searchParams.get('id')) {
            Navigate('/gem-state-chapters-list');
        }
        getGemStateChapterData();
    }, [])



    const handleChairmanFileUpload = (event) => {
        const file = event.target.files[0];
        setChairmanImage(file);
        setIsSuccess('');
        setErrorMessage('');
        setIsError(false);
    };

    const handleCoChairmanFileUpload = (event) => {
        const file = event.target.files[0];
        setCoChairmanImage(file);

        setIsSuccess('');
        setErrorMessage('');
        setIsError(false);
    };

    const handleSubmitApi = () => {
        if (!chairmanName) {
            setErrorMessage("Please Enter chairman Name!");
            setIsError(true);
            return;
        }
        if (!chairmanDesignation) {
            setErrorMessage("Please Enter Chairman Designation!");
            setIsError(true);
            return;
        }
        if (!coChairmanName) {
            setErrorMessage("Please Enter co-chairman Name!");
            setIsError(true);
            return;
        }
        if (!coChairmanDesignation) {
            setErrorMessage("Please Enter co-chairman Designation!");
            setIsError(true);
            return;
        }
        let data = {
            gem_chapter_id: searchParams.get('id'),
            chairman_name: chairmanName,
            chairman_designation: chairmanDesignation,
            chairman_image: chairmanImageOld,
            co_chairman_name: coChairmanName,
            co_chairman_designation: coChairmanDesignation,
            co_chairman_image: coChairmanImageOld
        }
        const formData = new FormData();
        formData.append('chairman_image', chairmanImage);
        formData.append('co_chairman_image', coChairmanImage);
        formData.append('data', JSON.stringify(data));
        Axios.post('api/admin/update-gem-state-chapter', formData, setIsLoading(true))
            .then((res) => {
                console.log(res);
                console.log(res);
                if (res?.data?.code == "success") {
                    setIsSuccess("Event Successfully Update");
                    getGemStateChapterData();
                }
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000)
            })
            .catch((error) => {
                console.log(error);
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000)
            })
    }

    return (
        <>
            <Sidebar />
            <div className='abt-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='abt-row'>
                                <h2>Gem State Chapter Manager</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='slect-form'>
                        {isError ? <span>{errorMessage}</span> : ""}
                        {isLoading ? <Loader /> : ""}
                        {isSuccess ? <span style={{ color: "green" }}>{isSuccess}</span> : ""}
                        <div className='slect-options'>
                            <label>Chairman Name</label>
                            <input type='text' value={chairmanName} onChange={(e) => setChairmanName(e.target.value)} />
                        </div>
                        <div className='slect-options'>
                            <label>Designation</label>
                            <input type='text' value={chairmanDesignation} onChange={(e) => setChairmanDesignation(e.target.value)} />
                        </div>

                        <div className="file-input-container">
                            <div className="file-input-wrapper">
                                {!chairmanImage && <span ><img style={{ height: "100px", width: "112px" }} src={process.env.REACT_APP_TEST_IMAGE_BASE_URL + chairmanImageOld} /></span>}
                                {chairmanImage && <span className="file-input-name">{chairmanImage.name}</span>}
                                <label htmlFor="chairmanImage" className="file-input-label">Change Chairman Image</label>
                                <input type="file" id="chairmanImage" className="file-input" onChange={handleChairmanFileUpload} />
                            </div>
                        </div>

                        <div className='slect-options'>
                            <label>Co-Chairman Name</label>
                            <input type='text' value={coChairmanName} onChange={(e) => setCoChairmanName(e.target.value)} />
                        </div>

                        <div className='slect-options'>
                            <label>Designation</label>
                            <input type='text' value={coChairmanDesignation} onChange={(e) => setCoChairmanDesignation(e.target.value)} />
                        </div>

                        <div className="file-input-container">
                            <div className="file-input-wrapper">
                                {!coChairmanImage && <span ><img style={{ height: "100px", width: "112px" }} src={process.env.REACT_APP_TEST_IMAGE_BASE_URL + coChairmanImageOld} /></span>}
                                {coChairmanImage && <span className="file-input-name">{coChairmanImage.name}</span>}
                                <label htmlFor="coChairmanImage" className="file-input-label">Upload Co-Chairman Image</label>
                                <input type="file" id="coChairmanImage" className="file-input" onChange={handleCoChairmanFileUpload} />
                            </div>
                        </div>
                        <button onClick={handleSubmitApi}>submit</button>

                    </div>


                </div>
            </div>
        </>
    )
}

export default GemChapterUpdate;