import React from 'react'
import Sidebar from '../layout/sidebar/Sidebar'

const Dashboard = () => {
    return (
        <>
            <Sidebar />
            <div>Dashboard</div>
        </>

    )
}

export default Dashboard