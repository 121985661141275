import './App.css';
import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './component/auth/Login';
import Header from './component/layout/header/Header';
import Footer from './component/layout/footer/Footer';
import Dashboard from './component/dashboard/Dashboard';
import Sidebar from './component/layout/sidebar/Sidebar';
import PrivateComponent from './services/PrivateComponent';
import PublicComponent from './services/PublicComponent';
import ManageMenu from './component/pages/manageStaticContent/ManageMenu';
import ManageSubMenu from './component/pages/manageStaticContent/ManageSubMenu';
import ManageContentMenu from './component/pages/manageStaticContent/ManageContentMenu';
import ShowContent from './component/pages/ShowContent';
import AddContent from './component/pages/manageStaticContent/AddContent';
import StaticContentList from './component/pages/manageStaticContent/StaticContentList';
import ManageEvent from './component/pages/manageEvent/ManageEvent';
import UpdateEvent from './component/pages/manageEvent/UpdateEvent';
import CreateQuestion from './component/pages/manageQuestionPaper/CreateQuestion';
import AssignedPaper from './component/pages/manageQuestionPaper/AssignedPaper';
import UpdateStaticContent from './component/pages/manageStaticContent/UpdateStaticContent';
import EventList from './component/pages/manageEvent/EventList';
import States from './component/pages/states/States';
import GemChapterState from './component/pages/gemChaptersManage/GemChapterState';
import GemChaptersList from './component/pages/gemChaptersManage/GemChaptersList';
import GemChapterUpdate from './component/pages/gemChaptersManage/GemChapterUpdate';
import RegistrationGemList from './component/pages/registrationGem/RegistrationGemList';
// import Sidebar from './component/layout/sidebar/Sidebar';
function App() {
  const [isHeader, setIsheader] = useState(false);
  useEffect(() => {
    let auth = localStorage.getItem('token');
    if (auth) {
      setIsheader(true);
    }
  })
  return (
    <div>
      {isHeader ? <Header setIsheader={setIsheader} /> : ""}

      <Routes>
        <Route element={<PrivateComponent />}>
          <Route path="/" element={<Dashboard />} />
          <Route path='/manage-menu' element={<ManageMenu />} />
          <Route path='/manage-sub-menu' element={<ManageSubMenu />} />
          <Route path='/manage-content-menu' element={<ManageContentMenu />} />
          <Route path='/show-content' element={<ShowContent />} />
          <Route path='/add-content' element={<AddContent />} />
          <Route path='/static-Content-list' element={<StaticContentList />} />
          <Route path='/static-Content-update' element={<UpdateStaticContent />} />
          <Route path='/manage-event' element={<ManageEvent />} />
          <Route path='/update-event' element={<UpdateEvent />} />
          <Route path='/create-question' element={<CreateQuestion />} />
          <Route path='/assigned-paper' element={<AssignedPaper />} />
          <Route path='/event-list' element={<EventList />} />
          <Route path='/states' element={<States />} />
          <Route path='/gem-state-chapters-manager' element={<GemChapterState />} />
          <Route path='/gem-state-chapters-list' element={<GemChaptersList />} />
          <Route path='/gem-state-chapters-update' element={<GemChapterUpdate />} />
          <Route path='/gem-registration-list' element={<RegistrationGemList />} />
        </Route>
        <Route element={<PublicComponent />}>
          <Route path="/login" element={<Login setIsheader={setIsheader} />} />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
