import React, { useEffect, useState } from 'react'
import Sidebar from '../../layout/sidebar/Sidebar';
import Axios from '../../../services/AxiosInstance';
import Loader from '../../common/Loader';

const States = () => {
    const [stateList, setStateList] = useState([]);
    const getStateList = () => {
        Axios.get(`api/admin/get-state-data`)
            .then((res) => {
                console.log("sdfjhdg", res.data.data);
                setStateList(res.data.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getStateList();
    }, [])

    const showStateList = stateList.map((statelist, index) => {
        return (

            <tr key={index}>
                <td>{statelist.id}</td>
                <td>{statelist.name}</td>
            </tr>
        )
    })

    return (
        <>
            <Sidebar />
            <div className='abt-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='abt-row'>
                                <h2>States</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-12'>
                        <div className='menu-view-cont'>
                            <table>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                Id
                                            </th>
                                            <th>
                                                State
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {showStateList}
                                    </tbody>
                                </table>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default States;