import React, { useEffect, useState } from 'react';
import Sidebar from '../../layout/sidebar/Sidebar';
import Axios from '../../../services/AxiosInstance';
import RenderHtml from './htmlRender';

const AssignedPaper = () => {
    const [paperName, setPaperName] = useState('');
    const [userID, setUserID] = useState('');
    const [examDate, setExamDate] = useState('');
    const [totalMinutes, setTotalMinutes] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [totalMarks, settotalMarks] = useState('');
    const [allQuestions, setAllQuestions] = useState([]);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [userRegistered, setUserRegistered] = useState([]);

    const getAllQuestion = () => {
        Axios.get(`api/admin/get-question-list`)
            .then((res) => {
                console.log(res);
                setAllQuestions(res.data.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getAllRegisteredUser = () => {
        Axios.get('api/admin/get-registered-user')
            .then((res) => {
                console.log(res);
                setUserRegistered(res.data.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getAllQuestion();
        getAllRegisteredUser();
    }, [])

    const handleChange = (e) => {
        let { name, checked } = e.target;
        console.log(name, checked);
        let tempList = allQuestions.map((ques) => {
            return name == ques.question_id ? { ...ques, isChecked: checked } : ques
        })
        setAllQuestions(tempList);
    }

    const showAllQuestion = allQuestions.map((ques, index) => {
        return (
            <div key={index} className="question-list-pg">
                <div className='question-name'>
                    <input
                        type="checkbox"
                        name={ques.question_id}
                        checked={ques?.isChecked || false}
                        onChange={handleChange}
                    />
                    <div className='question-img' ><RenderHtml htmlString={ques.question} /></div>
                </div>
                <div className='option-txt'>
                    <ul>
                        {ques.option1 ? <li><RenderHtml htmlString={ques.option1} /></li> : ""}
                        {ques.option2 ? <li><RenderHtml htmlString={ques.option2} /></li> : ""}
                        {ques.option3 ? <li><RenderHtml htmlString={ques.option3} /></li> : ""}
                        {ques.option4 ? <li><RenderHtml htmlString={ques.option4} /></li> : ""}
                    </ul>
                </div>


                <div className="answer-col">
                    <span>Answer : </span><div className="answer-name"><RenderHtml htmlString={ques.answer} /></div>
                </div>

            </div>
        )
    })

    const showAllRegisteredUser = userRegistered.map((user, index) => {
        return (
            <option key={index} value={user.registration_id}>{user.first_name + " " + user.last_name}</option>
        )
    })


    const handleCreatePaper = () => {
        console.log(paperName, userID, examDate, startTime, endTime, totalMarks, allQuestions);
        if (!paperName) {
            setErrorMessage("Please Enter Paper Name!");
            setIsError(true);
            return;
        }
        if (!userID) {
            setErrorMessage("Please Select User!");
            setIsError(true);
            return;
        }
        if (!examDate) {
            setErrorMessage("Please Assigned Exam Date");
            setIsError(true);
            return;
        }
        if (!totalMinutes) {
            setErrorMessage("Please Enter Time Duration!");
            setIsError(true);
            return;
        }
        if (!startTime) {
            setErrorMessage("Please Enter Start Time!");
            setIsError(true);
            return;
        } if (!endTime) {
            setErrorMessage("Please Enter End Time!");
            setIsError(true);
            return;
        } if (!totalMarks) {
            setErrorMessage("Please Enter Total Marks!");
            setIsError(true);
            return;
        }
        let count = 0;
        let selectedQuestionsIds = [];
        for (let i = 0; i < allQuestions.length; i++) {
            if (allQuestions[i]?.isChecked) {
                count += 1;
                selectedQuestionsIds.push({ question_id: allQuestions[i].question_id, right_answer: allQuestions[i].answer });
            }
        }
        console.log(count, selectedQuestionsIds);
        if (count <= 0) {
            setErrorMessage("Please Select the Question!");
            setIsError(true);
            return;
        }
        Axios.post('api/admin/create-exam-paper', {
            paper_name: paperName,
            user_id: userID,
            no_of_questions: count,
            assigned_date: examDate,
            total_minutes: totalMinutes,
            start_time: startTime,
            end_time: endTime,
            total_marks: totalMarks,
            selQuesIdsArr: selectedQuestionsIds,
        })
            .then((res) => {
                console.log(res);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <>
            <Sidebar />
            <div className='abt-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='abt-row'>
                                <h2>Assigned Exam</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='slect-option-section'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                {/* <form onSubmit={handleCreatePaper}> */}
                                <div className='question-list-date'>
                                    <div className='assigned-options'>
                                        <label>Enter Paper Name</label>
                                        <input type="text" value={paperName} onChange={(e) => setPaperName(e.target.value)} />
                                    </div>

                                    <div className='assigned-options'>
                                        <label>Select User</label>
                                        <select value={userID} onChange={(e) => setUserID(e.target.value)}>
                                            <option>select</option>
                                            {showAllRegisteredUser}
                                        </select>
                                    </div>

                                    <div className='assigned-options'>
                                        <label>Exam Date</label>
                                        <input type="date" value={examDate} onChange={(e) => setExamDate(e.target.value)} />
                                    </div>

                                    <div className='assigned-options'>
                                        <label>Time Duration In Minutes</label>
                                        <input type="text" value={totalMinutes} onChange={(e) => setTotalMinutes(e.target.value)} />
                                    </div>

                                    <div className='assigned-options'>
                                        <label>Start Time</label>
                                        <input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                                        {/* <label for="time">Start Time</label>
                                        <input type="text" id="time" name="time" pattern="\d{1,2}:\d{2} (AM|PM)" required/> */}
                                    </div>

                                    <div className='assigned-options'>
                                        <label>End Time</label>
                                        <input type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
                                        {/* <label for="time">Start Time</label>
                                        <input type="text" id="time" name="time" pattern="\d{1,2}:\d{2} (AM|PM)" required/> */}
                                    </div>

                                    <div className='question-options'>
                                        <h1>Select Question</h1>
                                        {showAllQuestion}
                                    </div>

                                    <div className='assigned-options total-marks'>
                                        <label>Total Marks</label>
                                        <input type="text" value={totalMarks} onChange={(e) => settotalMarks(e.target.value)} />
                                    </div>


                                </div>
                                <div className='total-marks-btn'>
                                    {isError ? <span>{errorMessage}</span> : ""}
                                    <button onClick={handleCreatePaper} type='submit'>Submit</button>
                                    {/* </form> */}
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default AssignedPaper;