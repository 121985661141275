import React, { useEffect, useState } from 'react'
import Sidebar from '../layout/sidebar/Sidebar';
import Axios from '../../services/AxiosInstance';

const ShowContent = () => {
    const [menuList, setMenuList] = useState([]);
    const [subMenuList, setSubMenuList] = useState([]);

    // const [menuName, setMenuName] = useState('');

        // const getSubMenuList = () => {
        //     Axios.get(`api/admin/get-sub-menu-list`)
        //         .then((res) => {
        //             console.log(res);
        //             setSubMenuList(res.data.data);
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         })
        // }

        const getShowContent = () => {
            Axios.get(`api/admin/show-content-list`)
            .then((res) => {
                console.log(res);
                setSubMenuList(res.data.data);
            })
            .catch((error) => {
                console.log(error);
            })
        }


    const showSubMenuList = subMenuList.map((submenu, index) => {
        return (
            <tr key={index}>
                <td>{submenu.menu_id}</td>
                <td>{submenu.menu_content}</td>
                <td>{new Date(submenu.added_date).toDateString()}</td>
                <td>{new Date(submenu.modified_date).toDateString()}</td>
                <td>{submenu.status}</td>
            </tr>
        )
    })

    const getMenuList = () => {
        Axios.get(`api/admin/get-menu-list`)
            .then((res) => {
                console.log(res);
                setMenuList(res.data.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }
    useEffect(() => {
        getMenuList();
        getShowContent();
    }, [])

    

    return (
        <>
            <Sidebar />
            <div className='abt-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='abt-row'>
                                <h2>Show Content</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='slect-option-section'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                               <div className='menu-view-cont'>
                               <table>
                                    <tr>
                                        <th>Sub Menu Id</th>
                                        <th>Sub Menu Name</th>
                                        <th>Added Date</th>
                                        <th>Modified Date</th>
                                        <th>Status</th>
                                    </tr>
                                    {showSubMenuList}
                                </table>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShowContent