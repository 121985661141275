import axios from "axios";
// import CryptoJS from 'crypto-js';
// import { useNavigate } from "react-router-dom";






// Set config defaults when creating the instance

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Alter defaults after instance has been created
//  instance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

// Add a request interceptor
instance.interceptors.request.use(
  config => {
    // let currentDate = new Date();
    // currentDate = currentDate.getDate() + '/' + currentDate.getMonth() + 1 + '/' + currentDate.getFullYear()
    // let apiKey = 'TZ202387191987$' + currentDate;
    // const EncryApiKey = CryptoJS.AES.encrypt(apiKey, process.env.REACT_APP_SALT_API_KEY).toString();
    config.headers.Authorization = `${localStorage.getItem('token')}`;
    // config.headers.tz_api_key = EncryApiKey;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
)

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, async function (error) {
  console.log(error);
  // if (error && error?.response?.data?.data?.not_valid == "token not valid!") {
  //   let currentDate = new Date();
  //   currentDate = currentDate.getDate() + '/' + currentDate.getMonth() + 1 + '/' + currentDate.getFullYear()
  //   let apiKey = 'TZ202387191987$' + currentDate;
  //   const EncryApiKey = CryptoJS.AES.encrypt(apiKey, process.env.REACT_APP_SALT_API_KEY).toString();
  //   await axios
  //     .get(`${process.env.REACT_APP_BASE_URL}api/bussiness/refresh-token`, {
  //       headers: {
  //         Authorization: localStorage.getItem('refreshToken'),
  //         tz_api_key: EncryApiKey
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res)
  //       if (res.data?.data?.token) {
  //         console.log(res);
  //         localStorage.setItem(
  //           'token',
  //           res.data.data.token,
  //         );
  //         localStorage.setItem(
  //           'refreshToken',
  //           res.data.data.refresh_token,
  //         );
  //       }
  //     }).catch((error => {
  //       if (error && error?.response?.data?.code == 422) {
  //         localStorage.clear();
  //         window.location.reload(false);
  //       }
  //     }));

  //   const oldRequest = error.config;
  //   oldRequest._retry = true;
  //   oldRequest.headers['Authorization'] = localStorage.getItem('token');
  //   return axios(oldRequest);
  // }

  // if (error && error?.response?.data?.message == "Your Account is Suspended!") {
  //   console.log("ssdds")
  //   localStorage.clear();
  //   window.location = '/suspended';
  // }
  return Promise.reject(error);
});

export default instance; 