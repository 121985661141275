import React, { useEffect, useState } from 'react';
import Axios from '../../../services/AxiosInstance';
import Sidebar from '../../layout/sidebar/Sidebar';
import RenderHtml from '../manageStaticContent/htmlRender';
import CustomPagination from '../../common/Pagination';
import { useNavigate } from 'react-router-dom';
const EventList = () => {
    const Navigate = useNavigate();
    const [eventList, setEventList] = useState([]);
    const [eventId, setEventId] = useState([]);

    const [totalPage, setTotalPage] = useState(0);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(2);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "ASC", });
    const [searchTerm, setSearchTerm] = useState(null);
    useEffect(() => {
        getEventList();
    }, [pageNum, pageSize, sortConfig, searchTerm])
    const getEventList = () => {
        Axios.get(`api/admin/get-event-list-with-pagination?page_num=${pageNum}&per_page_records=${pageSize}&sort_key=${sortConfig.key}&sort_direction=${sortConfig.direction}&search_term=${searchTerm}`)
            .then((res) => {
                console.log(res);
                setEventList(res.data.data.data);
                setTotalPage(res.data.data.total_pages);
            })
            .catch((error) => {
                console.log(error);
            })
    }


    const showEventList = eventList.map((event, index) => {
        return (
            <tr key={index}>
                <td>{event.event_id}</td>
                <td>{event.event_category}</td>
                <td><RenderHtml htmlString={event.event_content} /></td>
                <td>{event.location}</td>
                <td>{event.status}</td>
                <td>{new Date(event.added_date).toDateString()}</td>
                <td>{event.added_by}</td>
                <td>{new Date(event.modified_date).toDateString()}</td>
                <td>{event.modified_by}</td>
                <td>
                    <button onClick={() => handleDeleteEvent(event.event_id)}>Delete</button>
                    <button onClick={() => handleEditEvent(event.event_id)}>Edit</button>
                </td>
            </tr>
        )
    })

    const handleEditEvent = (event_id) => {
        Navigate(`/update-event?key=${event_id}`)
    }

    const handleDeleteEvent = (event_id) => {
        // console.log(event_id);
        Axios.post(`api/admin/delete-event?event_id=${event_id}`)
            .then((res) => {
                console.log(res);
                getEventList();
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setPageNum(1);
    }


    const handleSort = (key) => {
        let direction = "ASC";
        if (
            sortConfig.key === key &&
            sortConfig.direction === "ASC"
        ) {
            direction = "DESC";
        }
        setSortConfig({ key, direction });
    };

    const sortedData = React.useMemo(() => {
        let sorted = [...eventList];
        if (sortConfig.key !== null) {
            sorted.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === "ASC" ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === "ASC" ? 1 : -1;
                }
                return 0;
            });
        }
        return sorted;
    }, [eventList, sortConfig]);


    return (
        <>
            <Sidebar />
            <div className='abt-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='abt-row'>
                                <h2>Event List</h2>
                            </div>
                        </div>
                    </div>
                </div>



                <div className='slect-option-section'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="search-box">
                                    <input type="search" placeholder="Search" onChange={(e) => setSearchTerm(e.target.value)} />
                                </div>
                                <CustomPagination pageNum={pageNum} setPageNum={setPageNum} totalPage={totalPage} />
                                <div className='pg-slct-list'>
                                    <label>Page Size</label>
                                    <select onChange={(e) => handlePageSizeChange(e.target.value)}>
                                        <option >Select</option>
                                        <option value="1" >1</option>
                                        <option value="2" >2</option>
                                        <option value="5" >5</option>
                                        <option value="10" >10</option>
                                        <option value="20" >20</option>
                                    </select>
                                </div>
                                <div className='menu-view-cont table-responsive'>
                                    <table>
                                        <tr>
                                            <th>
                                                Event ID
                                                <span className={`sorting-logo ${sortConfig.key === "event_id" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("event_id")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "event_id" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("event_id")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>
                                                Category
                                                <span className={`sorting-logo ${sortConfig.key === "event_category" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("event_category")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "event_category" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("event_category")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>
                                                Event

                                            </th>
                                            <th>
                                                Location
                                                <span className={`sorting-logo ${sortConfig.key === "location" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("location")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "location" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("location")}>
                                                    ▼
                                                </span>
                                            </th>

                                            <th>Status</th>

                                            <th>
                                                Added Date
                                                <span className={`sorting-logo ${sortConfig.key === "added_date" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("added_date")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "added_date" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("added_date")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>Added By</th>
                                            <th>
                                                Modified Date
                                                <span className={`sorting-logo ${sortConfig.key === "modified_date" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("modified_date")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "modified_date" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("modified_date")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>Modified By</th>
                                            <th>Action</th>
                                        </tr>
                                        {showEventList}
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default EventList;