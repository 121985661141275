import React, { useEffect, useState } from 'react';
import Axios from '../../../services/AxiosInstance';
import Sidebar from '../../layout/sidebar/Sidebar';

import CustomPagination from '../../common/Pagination';
import { Link } from 'react-router-dom';

const RegistrationGemList = () => {
  const [gemChapterList, setGemChapterList] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "ASC", });
    const [searchTerm, setSearchTerm] = useState(null);

    const getGemList = () => {
        Axios.get(`api/admin/get-gem-Registration-list?page_num=${pageNum}&per_page_records=${pageSize}&sort_key=${sortConfig.key}&sort_direction=${sortConfig.direction}&search_term=${searchTerm}`)
            .then((res) => {
                console.log(res.data.data.total_pages);
                setGemChapterList(res.data.data.data);
                setTotalPage(res.data.data.total_pages);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const showList = gemChapterList.map((item, index) => {
        return (
            <tr key={index}>
                <td>{item.registration_id}</td>
                <td>{item.project_name}</td>
                <td>{item.sub_project_name}</td>
                <td>{item.salutations}</td>
                <td>{item.first_name}</td>
                <td>{item.last_name}</td>
                <td>{item.email_id}</td>
                <td>{item.address}</td>
                <td>{item.country}</td>
                <td>{item.state}</td>
                <td>{item.city}</td>
                <td>{item.added_by}</td>
                <td>{new Date(item.added_date).toDateString()}</td>
                <td>{item.modified_by}</td>
                <td>{new Date(item.modified_date).toDateString()}</td>
            </tr>
        )
    })


    useEffect(() => {
        getGemList();
    }, [pageNum, pageSize, sortConfig, searchTerm])



    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setPageNum(1);
    }


    const handleSort = (key) => {
        let direction = "ASC";
        if (
            sortConfig.key === key &&
            sortConfig.direction === "ASC"
        ) {
            direction = "DESC";
        }
        setSortConfig({ key, direction });
    };

    const sortedData = React.useMemo(() => {
        let sorted = [...gemChapterList];
        if (sortConfig.key !== null) {
            sorted.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === "ASC" ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === "ASC" ? 1 : -1;
                }
                return 0;
            });
        }
        return sorted;
    }, [gemChapterList, sortConfig]);

    return (
        <>
            <Sidebar />
            <div className='abt-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='abt-row'>
                                <h2>Registration for Gem List</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="search-box">
                                <input type="search" placeholder="Search" onChange={(e) => setSearchTerm(e.target.value)} />
                            </div>
                            <CustomPagination pageNum={pageNum} setPageNum={setPageNum} totalPage={totalPage} />
                            <div className='pg-slct-list'>
                                <label>Page Size</label>
                                <select onChange={(e) => handlePageSizeChange(e.target.value)}>
                                    <option >Select</option>
                                    <option value="10" >10</option>
                                    <option value="20" >20</option>
                                    <option value="50" >50</option>
                                    <option value="100" >100</option>
                                </select>
                            </div>
                            <div className='menu-view-cont table-responsive'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                Id
                                                <span className={`sorting-logo ${sortConfig.key === "registration_id" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("registration_id")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "registration_id" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("registration_id")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>
                                              Project Name
                                              <span className={`sorting-logo ${sortConfig.key === "project_name" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("project_name")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "project_name" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("project_name")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>
                                              Sub Project Name
                                              <span className={`sorting-logo ${sortConfig.key === "sub_project_name" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("sub_project_name")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "sub_project_name" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("sub_project_name")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>
                                              Salutations
                                              <span className={`sorting-logo ${sortConfig.key === "salutations" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("salutations")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "salutations" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("salutations")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>
                                              First Name
                                              <span className={`sorting-logo ${sortConfig.key === "first_name" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("first_name")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "first_name" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("first_name")}>
                                                    ▼
                                                </span>
                                              </th>
                                            <th>
                                              Last Name
                                              <span className={`sorting-logo ${sortConfig.key === "last_name" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("last_name")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "last_name" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("last_name")}>
                                                    ▼
                                                </span>
                                              </th>
                                            <th>
                                              Email Id
                                              <span className={`sorting-logo ${sortConfig.key === "email_id" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("email_id")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "email_id" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("email_id")}>
                                                    ▼
                                                </span>
                                              </th>
                                            <th>
                                              Address
                                              <span className={`sorting-logo ${sortConfig.key === "address" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("address")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "address" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("address")}>
                                                    ▼
                                                </span>
                                              </th>
                                            <th>
                                              Country
                                              <span className={`sorting-logo ${sortConfig.key === "country" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("country")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "country" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("country")}>
                                                    ▼
                                                </span>
                                              </th>
                                            <th>
                                              State
                                              <span className={`sorting-logo ${sortConfig.key === "state" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("state")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "state" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("state")}>
                                                    ▼
                                                </span>
                                              </th>
                                            <th>
                                              City
                                              <span className={`sorting-logo ${sortConfig.key === "city" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("city")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "city" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("city")}>
                                                    ▼
                                                </span>
                                              </th>
                                            <th>
                                              Added By
                                              <span className={`sorting-logo ${sortConfig.key === "added_by" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("added_by")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "added_by" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("added_by")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>
                                              Added Date
                                              <span className={`sorting-logo ${sortConfig.key === "added_date" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("added_date")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "added_date" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("added_date")}>
                                                    ▼
                                                </span>
                                              </th>
                                            <th>
                                              Modified By
                                              <span className={`sorting-logo ${sortConfig.key === "modified_by" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("modified_by")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "modified_by" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("modified_by")}>
                                                    ▼
                                                </span>
                                              </th>
                                            <th>
                                              Modified Date
                                              <span className={`sorting-logo ${sortConfig.key === "modified_date" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("modified_date")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "modified_date" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("modified_date")}>
                                                    ▼
                                                </span>
                                              </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {showList}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default RegistrationGemList;