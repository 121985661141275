import React, { useEffect, useState } from 'react';
import Axios from '../../../services/AxiosInstance';
import Sidebar from '../../layout/sidebar/Sidebar';

import CustomPagination from '../../common/Pagination';
import { Link } from 'react-router-dom';


const GemChaptersList = () => {

    const [gemChapterList, setGemChapterList] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "ASC", });
    const [searchTerm, setSearchTerm] = useState(null);

    const getGemList = () => {
        Axios.get(`api/admin/get-gem-chapters-list?page_num=${pageNum}&per_page_records=${pageSize}&sort_key=${sortConfig.key}&sort_direction=${sortConfig.direction}&search_term=${searchTerm}`)
            .then((res) => {
                console.log(res.data.data.total_pages);
                setGemChapterList(res.data.data.data);
                setTotalPage(res.data.data.total_pages);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const showList = gemChapterList.map((item, index) => {
        return (
            <tr key={index}>
                <td>{item.gem_chapter_id}</td>
                <td>{item.chairman_name}</td>
                <td>{item.chairman_designation}</td>
                <td><img src={process.env.REACT_APP_TEST_IMAGE_BASE_URL + item.chairman_image} style={{ height: "50px", width: "50px" }} /></td>
                <td>{item.co_chairman_name}</td>
                <td>{item.co_chairman_designation}</td>
                <td><img src={process.env.REACT_APP_TEST_IMAGE_BASE_URL + item.co_chairman_image} style={{ height: "50px", width: "50px" }} /></td>
                <td>{item.state_name}</td>
                <td>{item.added_by}</td>
                <td>{new Date(item.added_date).toDateString()}</td>
                <td>{item.modified_by}</td>
                <td>{new Date(item.modified_date).toDateString()}</td>
                <td>
                    <button onClick={() => handleDeleteApi(item.gem_chapter_id)}>Delete</button>
                    <Link to={`/gem-state-chapters-update?id=${item.gem_chapter_id}`}><button>update</button></Link>
                </td>
            </tr>
        )
    })

    const handleDeleteApi = (id) => {
        Axios.post(`api/admin/gem-chapter-delete?id=${id}`)
            .then((res) => {
                console.log(res);
                getGemList();
            })
            .catch((error) => {
                console.log(error);
            })
    }


    useEffect(() => {
        getGemList();
    }, [pageNum, pageSize, sortConfig, searchTerm])



    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setPageNum(1);
    }


    const handleSort = (key) => {
        let direction = "ASC";
        if (
            sortConfig.key === key &&
            sortConfig.direction === "ASC"
        ) {
            direction = "DESC";
        }
        setSortConfig({ key, direction });
    };

    const sortedData = React.useMemo(() => {
        let sorted = [...gemChapterList];
        if (sortConfig.key !== null) {
            sorted.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === "ASC" ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === "ASC" ? 1 : -1;
                }
                return 0;
            });
        }
        return sorted;
    }, [gemChapterList, sortConfig]);

    return (
        <>
            <Sidebar />
            <div className='abt-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='abt-row'>
                                <h2>Gem State Chapter Manager</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="search-box">
                                <input type="search" placeholder="Search" onChange={(e) => setSearchTerm(e.target.value)} />
                            </div>
                            <CustomPagination pageNum={pageNum} setPageNum={setPageNum} totalPage={totalPage} />
                            <div className='pg-slct-list'>
                                <label>Page Size</label>
                                <select onChange={(e) => handlePageSizeChange(e.target.value)}>
                                    <option >Select</option>
                                    <option value="10" >10</option>
                                    <option value="20" >20</option>
                                    <option value="50" >50</option>
                                    <option value="100" >100</option>
                                </select>
                            </div>
                            <div className='menu-view-cont table-responsive'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                Id
                                                <span className={`sorting-logo ${sortConfig.key === "gem_chapter_id" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("gem_chapter_id")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "gem_chapter_id" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("gem_chapter_id")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>
                                                Chairman
                                                <span className={`sorting-logo ${sortConfig.key === "chairman_name" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("chairman_name")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "chairman_name" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("chairman_name")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>Designation</th>
                                            <th>Image</th>
                                            <th>
                                                Co-Chairman
                                                <span className={`sorting-logo ${sortConfig.key === "co_chairman_name" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("co_chairman_name")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "co_chairman_name" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("co_chairman_name")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>Designation</th>
                                            <th>Image</th>
                                            <th>
                                                state
                                                <span className={`sorting-logo ${sortConfig.key === "state_name" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("state_name")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "state_name" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("state_name")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>Added By</th>
                                            <th>Added Date</th>
                                            <th>Modified By</th>
                                            <th>Modified Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {showList}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default GemChaptersList