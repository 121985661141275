import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import logoimg from "../../assets/images/logo-img1.png";
import logoimg1 from "../../assets/images/logo-img2.png";
import { useFormik } from "formik";
import Axios from '../../services/AxiosInstance';
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

const Login = (props) => {
    const Navigate = useNavigate();
    const loginAPI = async () => {
        console.log(values.password)
        let EncryPassword = CryptoJS.AES.encrypt(values.password, process.env.REACT_APP_LOGIN_PASSWORD_KEY).toString();
        // console.log(EncryPassword);
        Axios.post(`api/admin/login`, {
            mobile_no: values.mobile_no,
            password: EncryPassword
        })
            .then((res) => {
                console.log(res);
                localStorage.setItem('token', res.data.data.token);
                props.setIsheader(true);
                Navigate('/');
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const { values, handleSubmit, handleChange } = useFormik({
        initialValues: {
            mobile_no: "",
            password: ""
        },
        onSubmit: loginAPI,
    })
    return (
        <div className="admin-sec">
            <div className="logo">
                <ul>
                    <li><img src={logoimg1} alt="" /></li>
                    <li><img src={logoimg} alt="" /></li>
                </ul>

            </div>
            <div className="login-pg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="admin-cont">
                                <div className="admin-hd">
                                    <h1>Admin login</h1>
                                </div>
                                <form onSubmit={handleSubmit} className="login-form">
                                    <div className="login-txt">
                                        <FaUserAlt /><input
                                            type="text"
                                            name="mobile_no"
                                            onChange={handleChange}
                                            value={values.mobile_no}
                                        />
                                    </div>
                                    <div className="login-txt">
                                        <FaLock /><input
                                            type="password"
                                            name="password"
                                            onChange={handleChange}
                                            value={values.password}
                                        />
                                    </div>
                                    <label><input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="checkbox-signin"
                                    />Remember me</label>
                                    <div className="login-btn">
                                        <button class="btn" type="submit"> Log In </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;