import React, { useState, useEffect, useMemo, useRef } from 'react'
import Sidebar from '../../layout/sidebar/Sidebar';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Axios from '../../../services/AxiosInstance';
import JoditEditor from 'jodit-react';

import Loader from '../../common/Loader';
const UpdateEvent = () => {
    let Navigate = useNavigate();
    let [searchParams] = useSearchParams();

    const [content, setContent] = useState('');
    const [placeholder, setPlaceholder] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (!searchParams.get('key')) {
            Navigate('/event-list');
        }
        getData();
    }, [searchParams])

    const getData = () => {
        Axios.get(`api/admin/get-event-with-Id?key=${searchParams.get('key')}`)
            .then((res) => {
                setContent(res.data.data[0].event_content);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const updateEventApi = () => {
        const dynamicVariable = process.env.REACT_APP_TEST_IMAGE_BASE_URL;
        const pattern = new RegExp(dynamicVariable, "g");
        let str = content.replace(pattern, '').replace(/"/g, "\\'");
        console.log(str);

        if (!content) {
            setErrorMessage("Please enter the Event!");
            setIsError(true);
            return;
        }
        Axios.post(`api/admin/update-event`, {
            event_id: searchParams.get('key'),
            event_content: str
        }, setIsLoading(true))
            .then((res) => {
                console.log(res);
                if (res?.data?.code == "success") {
                    setIsSuccess("Event Successfully Update");
                }
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000)
            })
            .catch((error) => {
                console.log(error);
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000)
                if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                    setIsError(true);
                }
            })
    }

    const editor = useRef(null);

    let first = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/,
        placeholder: placeholder || 'Start typings...',
        zIndex: 0,
        activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
        toolbarButtonSize: 'middle',
        theme: 'default',
        saveModeInCookie: false,
        spellcheck: true,
        editorCssClass: false,
        triggerChangeEvent: true,
        width: 'auto',
        height: 'auto',
        minHeight: 300,
        direction: '',
        language: 'auto',
        debugLanguage: false,
        i18n: 'en',
        tabIndex: -1,
        toolbar: true,
        enter: "P",
        // defaultMode: JoditEditor.MODE_WYSIWYG,
        useSplitMode: false,
        // enableDragAndDropFileToEditor: true,
        colors: {
            greyscale: ['#000000', '#434343', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
            palette: ['#980000', '#FF0000', '#FF9900', '#FFFF00', '#00F0F0', '#00FFFF', '#4A86E8', '#0000FF', '#9900FF', '#FF00FF'],
            full: [
                '#E6B8AF', '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#C9DAF8', '#CFE2F3', '#D9D2E9', '#EAD1DC',
                '#DD7E6B', '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#A4C2F4', '#9FC5E8', '#B4A7D6', '#D5A6BD',
                '#CC4125', '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6D9EEB', '#6FA8DC', '#8E7CC3', '#C27BA0',
                '#A61C00', '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#3D85C6', '#674EA7', '#A64D79',
                '#85200C', '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#1155CC', '#0B5394', '#351C75', '#733554',
                '#5B0F00', '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#1C4587', '#073763', '#20124D', '#4C1130'
            ]
        },
        colorPickerDefaultTab: 'background',
        imageDefaultWidth: 300,
        disablePlugins: [],
        extraButtons: [],
        sizeLG: 900,
        sizeMD: 700,
        sizeSM: 400,
        sizeSM: 400,
        buttons: [
            'source',
            '|',
            'bold',
            'strikethrough',
            'underline',
            'italic', '|',
            'ul',
            'ol', '|',
            'outdent', 'indent', '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'image',
            // 'video',
            'table',
            'link', '|',
            'align', 'undo', 'redo', '|',
            'hr',
            'eraser',
            // 'copyformat', 
            '|',
            // 'symbol',
            'fullsize',
            // 'print',
            'about'
        ],
        uploader: {
            url: `${process.env.REACT_APP_BASE_URL}api/admin/cms-content-upload-image`,
            format: "json",
            headers: {
                Authorization: `${localStorage.getItem('token')}`
            },
            prepareData: (formData) => {
                // console.log(formData);
                // You can prepare data for uploading here
                return formData;
            },
            isSuccess: (response) => {
                // Check if the upload was successful
                // console.log(response.data);
                return response.code === "success" && response.data.imageUrl;
            },
            process: function (resp) {
                return {
                    files: resp.files || [],
                    path: resp.data.imageUrl,
                    baseurl: process.env.REACT_APP_TEST_IMAGE_BASE_URL,
                    // error: resp.error,
                    // msg: resp.data.imageUrl
                };
            },

            defaultHandlerSuccess: function (data, resp) {
                // var i, field = 'files';
                console.log(this)
                this.s.insertImage(data.baseurl + data.path);
                // if (data[field] && data[field].length) {
                //     for (i = 0; i < data[field].length; i += 1) {
                //         console.log(data.baseurl + data[field][i]);
                //         this.s.insertImage(data.baseurl + data[field][i]);
                //     }
                // }
            },
            error: function (e) {
                this.e.fire('errorMessage', [e.getMessage(), 'error', 4000]);
            }
            // insertImageAsBase64URI: false
        },
        removeButtons: ['file'],
        showXPathInStatusbar: false,
        showCharsCounter: false,
        showWordsCounter: false,
        toolbarAdaptive: true,
        toolbarSticky: true,
        style: {
            background: '#fff',
            color: '#000',
        },
    }

    const config = useMemo(() =>
        first,
        [placeholder]
    );

    return (
        <>
            <Sidebar />
            <div className='abt-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='abt-row'>
                                <h2>Edit Event</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='slect-option-section'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>

                                <div className='jodi-editor'>
                                    <JoditEditor
                                        ref={editor}
                                        value={content}
                                        config={config}
                                        tabIndex={1} // tabIndex of textarea
                                        onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                        onChange={newContent => setContent(newContent)}
                                    />
                                </div>

                                {isError ? <span>{errorMessage}</span> : ""}
                                {isLoading ? <Loader /> : ""}
                                {isSuccess ? <span style={{ color: "green" }}>{isSuccess}</span> : ""}
                                <button onClick={updateEventApi} className="static-cont-btn">submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateEvent;