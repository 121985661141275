import React, { useEffect, useState } from 'react'
import Sidebar from '../../layout/sidebar/Sidebar';
import Axios from '../../../services/AxiosInstance';
import Loader from '../../common/Loader';
import CustomPagination from '../../common/Pagination';
const ManageSubMenu = () => {
    const [menuList, setMenuList] = useState([]);
    const [subMenuList, setSubMenuList] = useState([]);
    const [subMenuName, setSubMenuName] = useState('');
    const [errorMessage, setErrorMessage] = useState();
    const [isError, setIsError] = useState(false);

    // const [menuName, setMenuName] = useState('');
    const [menuId, setMenuId] = useState(0);

    const [isLoading, setIsLoading] = useState(false);

    const [isSuccess, setIsSuccess] = useState('');


    const [totalPage, setTotalPage] = useState(0);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(2);

    const [sortConfig, setSortConfig] = useState({ key: null, direction: "ASC", });

    const [updateMenu, setUpdateMenu] = useState({ oldvalue: '', value: '', menu_id: '', isUpdate: false });

    const getSubMenuListWithPag = () => {
        Axios.get(`api/admin/get-sub-menu-list-pagination?parent_id=${menuId}&page_num=${pageNum}&per_page_records=${pageSize}&sort_key=${sortConfig.key}&sort_direction=${sortConfig.direction}`)
            .then((res) => {
                console.log("sds", res.data.data);
                setSubMenuList(res.data.data.data);
                setTotalPage(res.data.data.total_pages);
            })
            .catch((error) => {
                console.log(error);
            })
    }


    const showSubMenuList = subMenuList.map((submenu, index) => {
        return (
            <tr key={index}>
                <td>{submenu.menu_id}</td>
                <td>
                    {
                        updateMenu.menu_id == submenu.menu_id && updateMenu.isUpdate ?
                            <>
                                <input value={updateMenu.value} onChange={(e) => setUpdateMenu({ ...updateMenu, value: e.target.value, isUpdate: true })} />
                                {isLoading ? <Loader /> : ""}
                            </>
                            :
                            submenu.menu_name
                    }
                </td>
                <td>{submenu.parent_id}</td>
                {/* <td>{submenu.menu_name}</td> */}
                <td>{submenu.added_by}</td>
                <td>{new Date(submenu.added_date).toDateString()}</td>
                <td>{submenu.modified_by}</td>
                <td>{new Date(submenu.modified_date).toDateString()}</td>
                <td>{submenu.status}</td>
                <td>
                    {!updateMenu.isUpdate ?
                        < button onClick={() => setUpdateMenu({ oldvalue: submenu.menu_name, value: submenu.menu_name, menu_id: submenu.menu_id, isUpdate: true })}>update</button>
                        :
                        < button onClick={() => handleUpdateMenuName()}>Update</button>
                    }
                    <button onClick={() => handleDeleteMenuName(submenu.menu_id)}>Delete</button>
                </td>

            </tr>
        )
    })

    const handleUpdateMenuName = () => {
        if (updateMenu.oldvalue !== updateMenu.value && updateMenu.isUpdate && updateMenu.value) {
            Axios.post('api/admin/update-menu-name', {
                menu_name: updateMenu.value,
                menu_id: updateMenu.menu_id
            }, setIsLoading(true))
                .then((res) => {
                    console.log(res.status);

                    setTimeout(() => {
                        setIsLoading(false);
                        if (res?.status === 200) {
                            setUpdateMenu({ oldvalue: '', value: '', menu_id: '', isUpdate: false });
                            getSubMenuListWithPag();
                        }
                    }, 1000)
                })
                .catch((error) => {
                    console.log(error)
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 1000)
                })
        }
    }

    const handleDeleteMenuName = (menu_id) => {
        Axios.post('api/admin/delete-menu-name', {
            menu_id: menu_id,
        })
            .then((res) => {

                setTimeout(() => {
                    setIsLoading(false);
                    if (res?.status === 200) {
                        getSubMenuListWithPag();
                    }
                }, 1000)

            })
            .catch((error) => {
                console.log(error);
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000)
            })
    }

    const getMenuList = () => {
        Axios.get(`api/admin/get-menu-list`)
            .then((res) => {
                console.log("sdfjhdg", res);
                setMenuList(res.data.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }
    useEffect(() => {
        getMenuList();
    }, [])

    useEffect(() => {
        getSubMenuListWithPag();
    }, [pageNum, pageSize, menuId, sortConfig])

    const showMenuOption = menuList.map((menu, index) => {
        return (
            <option key={index} value={menu.menu_id}>{menu.menu_name}</option>

        )
    })


    const handleAddSubMenu = () => {
        setIsSuccess('');
        if (!menuId) {
            setErrorMessage("Please Select Menu!");
            setIsError(true);
            return;
        }
        if (!subMenuName) {
            setErrorMessage("Please Enter Something!");
            setIsError(true);
            return;
        }
        Axios.post(`api/admin/add-sub-menu`, {
            menu_name: subMenuName,
            parent_id: menuId
        }, setIsLoading(true))
            .then((res) => {
                getSubMenuListWithPag();
                setIsError(false);
                setErrorMessage('');
                if (res?.data?.code == "success") {
                    setIsSuccess("SubMenu Successfully Created");
                }
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000)
            })
            .catch((error) => {
                console.log(error);
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000)
                if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                    setIsError(true);
                }
            })
    }


    const handleSort = (key) => {
        let direction = "ASC";
        if (
            sortConfig.key === key &&
            sortConfig.direction === "ASC"
        ) {
            direction = "DESC";
        }
        setSortConfig({ key, direction });
    };

    const sortedData = React.useMemo(() => {
        let sorted = [...subMenuList];
        if (sortConfig.key !== null) {
            sorted.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === "ASC" ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === "ASC" ? 1 : -1;
                }
                return 0;
            });
        }
        return sorted;
    }, [subMenuList, sortConfig]);

    const handleSelectMenuId = (menu_id) => {
        setMenuId(menu_id);
        setIsError(false);
        setErrorMessage('');
        setIsSuccess('');
    }
    const handChangeSubmenu = (sub_menu_name) => {
        setSubMenuName(sub_menu_name);
        setIsError(false);
        setErrorMessage('');
        setIsSuccess('');
    }

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setPageNum(1);
    }
    return (
        <>
            <Sidebar />
            <div className='abt-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='abt-row'>
                                <h2>Sub Menu Manager</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='slect-option-section'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='slect-form'>
                                    <div className='slect-option'>
                                        <label>Select Menu</label>
                                        <select
                                            name=""
                                            id=""
                                            onChange={(e) => handleSelectMenuId(e.target.value)}
                                        >
                                            <option>Select Menu</option>
                                            {showMenuOption}
                                        </select>
                                    </div>
                                    <div className='slect-sub-option'>
                                        <label htmlFor='add-menu'>Add Sub Menu</label>
                                        <input
                                            name="add-menu"
                                            id="add-menu"
                                            value={subMenuName}
                                            onChange={(e) => handChangeSubmenu(e.target.value)}
                                        />

                                        <button onClick={handleAddSubMenu}>Add</button>
                                        {isError ? <span>{errorMessage}</span> : ""}
                                        {isLoading ? <Loader /> : ""}
                                        {isSuccess ? <span style={{ color: "green" }}>{isSuccess}</span> : ""}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='menu-view-cont'>
                                    <table>
                                        <tr>
                                            <th>
                                                Sub Menu Id
                                                <span className={`sorting-logo ${sortConfig.key === "menu_id" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("menu_id")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "menu_id" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("menu_id")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>
                                                Sub Menu Name
                                                <span className={`sorting-logo ${sortConfig.key === "menu_name" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("menu_name")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "menu_name" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("menu_name")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>Parent Id</th>
                                            {/* <th>Menu Name</th> */}
                                            <th>Added By</th>
                                            <th>Added Date
                                                <span className={`sorting-logo ${sortConfig.key === "added_date" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("added_date")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "added_date" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("added_date")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>Modified By</th>
                                            <th>Modified Date
                                                <span className={`sorting-logo ${sortConfig.key === "modified_date" && sortConfig.direction === "ASC" ? "active" : ""}`} onClick={() => handleSort("modified_date")}>
                                                    ▲
                                                </span>
                                                <span className={`sorting-logo ${sortConfig.key === "modified_date" && sortConfig.direction === "DESC" ? "active" : ""}`} onClick={() => handleSort("modified_date")}>
                                                    ▼
                                                </span>
                                            </th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                        {showSubMenuList}
                                    </table>
                                </div>
                                <CustomPagination pageNum={pageNum} setPageNum={setPageNum} totalPage={totalPage} />
                                <label>Page Size</label>
                                <select onChange={(e) => handlePageSizeChange(e.target.value)}>
                                    <option >Select</option>
                                    <option value="1" >1</option>
                                    <option value="2" >2</option>
                                    <option value="5" >5</option>
                                    <option value="10" >10</option>
                                    <option value="20" >20</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageSubMenu