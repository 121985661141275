import React, { useEffect, useState } from 'react';
import Axios from '../../../services/AxiosInstance';
import Sidebar from '../../layout/sidebar/Sidebar';
import Loader from '../../common/Loader';
const GemChapterState = () => {
    const [stateList, setStateList] = useState([]);
    const [stateId, setStateId] = useState('');
    const [chairmanName, setChairmanName] = useState('');
    const [chairmanDesignation, setChairmanNameDesignation] = useState('');
    const [chairmanImage, setChairmanImage] = useState('');
    const [coChairmanName, setCoChairmanName] = useState('');
    const [coChairmanDesignation, setCoChairmanNameDesignation] = useState('');
    const [coChairmanImage, setCoChairmanImage] = useState('');

    const [errorMessage, setErrorMessage] = useState();
    const [isError, setIsError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState('');

    const [isAlreadyContentExists, setIsAlreadyContentExists] = useState('');


    const getStateList = () => {
        Axios.get(`api/admin/get-state-data`)
            .then((res) => {
                console.log("sdfjhdg", res.data.data);
                setStateList(res.data.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getStateList();
    }, [])

    const selectShowState = stateList.map((item, index) => {
        return (
            <option value={item.id} key={index}>{item.name}</option>
        )
    })

    const handleSubmitApi = () => {
        if (!stateId) {
            setErrorMessage("Please Select State!");
            setIsError(true);
            return;
        }
        if (isAlreadyContentExists == 'Yes') {
            setErrorMessage("Content Already Exists. if you want to Update go to update page!");
            setIsError(true);
            return;
        }
        if (!chairmanName) {
            setErrorMessage("Please Enter chairman Name!");
            setIsError(true);
            return;
        }
        if (!chairmanDesignation) {
            setErrorMessage("Please Enter Chairman Designation!");
            setIsError(true);
            return;
        }
        if (!chairmanImage) {
            setErrorMessage("Please Choose chairman image!");
            setIsError(true);
            return;
        }
        if (!coChairmanName) {
            setErrorMessage("Please Enter co-chairman Name!");
            setIsError(true);
            return;
        }
        if (!coChairmanDesignation) {
            setErrorMessage("Please Enter co-chairman Designation!");
            setIsError(true);
            return;
        }
        if (!coChairmanImage) {
            setErrorMessage("Please Choose co-chairman image!");
            setIsError(true);
            return;
        }
        let data = {
            chairman_name: chairmanName,
            chairman_designation: chairmanDesignation,
            co_chairman_name: coChairmanName,
            co_chairman_designation: coChairmanDesignation,
            state_id: stateId,
        }
        const formData = new FormData();
        formData.append('chairman_image', chairmanImage);
        formData.append('co_chairman_image', coChairmanImage);
        formData.append('data', JSON.stringify(data));
        Axios.post('api/admin/insert-gem-chapter', formData, setIsLoading(true))
            .then((res) => {
                console.log(res);
                if (res?.data?.code == "success") {
                    setIsSuccess("Content Successfully Added");
                    setIsError(false);
                    setErrorMessage('');
                }
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000)
            })
            .catch((error) => {
                console.log(error);
                if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                    setIsError(true);
                }
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000)
            })
    }

    const handleChairmanFileUpload = (event) => {
        const file = event.target.files[0];
        setChairmanImage(file);

        setIsSuccess('');
        setErrorMessage('');
        setIsError(false);
    };

    const handleCoChairmanFileUpload = (event) => {
        const file = event.target.files[0];
        setCoChairmanImage(file);

        setIsSuccess('');
        setErrorMessage('');
        setIsError(false);
    };

    const handleStateId = (state_id) => {
        setStateId(state_id);
        setIsSuccess('');
        setErrorMessage('');
        setIsError(false);
        Axios.get(`api/admin/check-state-chapter-is-exists?state_id=${state_id}`)
            .then((res) => {
                setIsAlreadyContentExists(res.data.data.status);
                console.log("jdfdfsj", res);
                if (res?.data?.data?.status == 'Yes') {
                    setErrorMessage("Chapter Already Exists At this State!");
                    setIsError(true);
                    return;
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <>
            <Sidebar />
            <div className='abt-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='abt-row'>
                                <h2>Gem State Chapter Manager</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='slect-form'>
                        {isError ? <span>{errorMessage}</span> : ""}
                        {isLoading ? <Loader /> : ""}
                        {isSuccess ? <span style={{ color: "green" }}>{isSuccess}</span> : ""}
                        <div className='slect-options'>
                            <label>Select Menu</label>
                            <select
                                name=""
                                id=""
                                onChange={(e) => handleStateId(e.target.value)}
                            >
                                <option>Select Menu</option>
                                {selectShowState}
                            </select>
                        </div>
                    </div>
                    <div className='slect-form'>
                        <div className='slect-options'>
                            <label>Chairman Name</label>
                            <input type='text' onChange={(e) => setChairmanName(e.target.value)} />
                        </div>
                        <div className='slect-options'>
                            <label>Designation</label>
                            <input type='text' onChange={(e) => setChairmanNameDesignation(e.target.value)} />
                        </div>

                        <div className="file-input-container">
                            <div className="file-input-wrapper">
                                <label htmlFor="chairmanImage" className="file-input-label">Upload Chairman Image</label>
                                <input type="file" id="chairmanImage" className="file-input" onChange={handleChairmanFileUpload} />
                                {chairmanImage && <span className="file-input-name">{chairmanImage.name}</span>}
                            </div>
                        </div>

                        <div className='slect-options'>
                            <label>Co-Chairman Name</label>
                            <input type='text' onChange={(e) => setCoChairmanName(e.target.value)} />
                        </div>

                        <div className='slect-options'>
                            <label>Designation</label>
                            <input type='text' onChange={(e) => setCoChairmanNameDesignation(e.target.value)} />
                        </div>

                        <div className="file-input-container">
                            <div className="file-input-wrapper">
                                <label htmlFor="coChairmanImage" className="file-input-label">Upload Co-Chairman Image</label>
                                <input type="file" id="coChairmanImage" className="file-input" onChange={handleCoChairmanFileUpload} />
                                {coChairmanImage && <span className="file-input-name">{coChairmanImage.name}</span>}
                            </div>
                        </div>
                        <button onClick={handleSubmitApi}>submit</button>

                    </div>


                </div>
            </div>
        </>
    )
}

export default GemChapterState;